<template>
  <ion-page>
    <ion-header v-if="!noPageHeader" :class="headerClass + (minimizeHeader ? ' minimized' : '')">
      <ion-toolbar :class="toolbarClass" :color="toolbarColor">
        <ion-buttons slot="start">
          <ion-back-button
            v-if="pageDefaultBackLink"
            :default-href="pageDefaultBackLink"
            :class="backButtonClass"
          ></ion-back-button>
          <slot name="actions-start"></slot>
        </ion-buttons>
        <slot name="search-bar"></slot>
        <ion-title v-if="pageTitle">{{ pageTitle }}</ion-title>
        <ion-buttons slot="end">
          <slot name="actions-end"></slot>
          <select-language v-if="selectLanguage"></select-language>
          <toolbar-download-button v-if="browser && appDownloadBtn"></toolbar-download-button>
        </ion-buttons>
      </ion-toolbar>
      <slot name="segment"></slot>
    </ion-header>
    <ion-content
      ref="ionContent"
      :class="contentClass"
      :color="contentColor"
      :scroll-events="contentScrollEvents"
      @ionScroll="onContentScroll($event)"
    >
      <slot name="ion-content"></slot>
    </ion-content>
  </ion-page>
</template>


<script>
import {
  IonPage,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
} from "@ionic/vue";

import SelectLanguage from '@/views/components/UI components/SelectLanguage';
import ToolbarDownloadButton from '../components/UI components/ToolbarDownloadButton.vue';

export default {
  emits: ["content"],
  props: {
    "pageTitle": {
      type: String
    },
    "pageDefaultBackLink": {
      type: String
    },
    "noPageHeader": {
      type: Boolean
    },
    "toolbarClass": {
      type: String
    },
    "headerClass": {
      type: String
    },
    "contentClass": {
      type: String
    },
    "contentColor": {
      type: String
    },
    "toolbarColor": {
      type: String
    },
    "backButtonClass": {
      type: String
    },
    "contentScrollEvents": {
      type: Boolean
    },
    "onContentScroll": {
      type: Function
    },
    "appDownloadBtn": {
      default: true
    },
    selectLanguage: {
      type: Boolean,
      default: false
    },

    minimizeHeader: { //A prop typically from pages that require a minimized header on scroll. Adds a minimized class to the ion header when true
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },

  components: {
    IonPage,
    IonHeader,
    IonTitle,
    IonContent,
    IonToolbar,
    IonBackButton,
    IonButtons,
    SelectLanguage,
    ToolbarDownloadButton
  },

  mounted() {
    //Emit the content ref to parent component for scroll manipulation purposes
    this.$emit("content", this.$refs.ionContent)
  }
};
</script>


<style lang="scss" scoped>

ion-header {
  top: 0;
  transition: top .2s;
  &.minimized {
    top: -56px;

    ion-toolbar {
      opacity: 0;
    }
  }
}
</style>