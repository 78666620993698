<template>
  <div class="demonstration">
    <p>Start profiting with our signals!</p>
    <div class="logo-box">
      <div class="behind"></div>
      <div class="logo">
        <img :src="logo" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/img/logo.webp";

export default {
  data() {
    return {
      logo,
    };
  },
};
</script>

<style scoped lang="scss">
.demonstration {
  width: 100%;

  p {
    font-size: 11px;
  }

  .logo-box {
    width: 56px;
    height: 56px;
    position: relative;
    margin: 0 auto;

    .behind {
      border-radius: 15px;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        116.63deg,
        #55DBB8 8.97%,
        #52D5D7 92.93%
      );
      position: absolute;
      right: 8px;
      top: 1px;
      transform: rotate(-20deg);
      z-index: 1;
    }

    .logo {
      position: relative;
      border-radius: 15px;
      overflow: hidden;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}
</style>