<template>
  <ion-page>
    <ion-header v-if="!noPageHeader" :class="headerClass" v-show="!keyboard">
      <ion-toolbar :class="toolbarClass">
        <ion-buttons slot="start">
          <ion-back-button
            :default-href="pageDefaultBackLink"
          ></ion-back-button>
          <slot name="actions-start"></slot>
        </ion-buttons>
        <ion-title v-if="pageTitle">{{ pageTitle }}</ion-title>
        <ion-buttons slot="end">
          <slot name="actions-end"></slot>
          <select-language></select-language>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <slot></slot>
    </ion-content>
  </ion-page>
</template>


<script>
import {
  IonPage,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
} from "@ionic/vue";

import SelectLanguage from "@/views/components/UI components/SelectLanguage";

export default {
  props: [
    "pageTitle",
    "pageDefaultBackLink",
    "noPageHeader",
    "toolbarClass",
    "headerClass",
    "keyboard",
  ],
  data() {
    return {};
  },
  components: {
    IonPage,
    IonHeader,
    IonTitle,
    IonContent,
    IonToolbar,
    IonBackButton,
    IonButtons,
    SelectLanguage,
  },
};
</script>


<style scoped>
</style>