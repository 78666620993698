<template>
  <ion-page>
    <ion-header class="fixed-home">
      <ion-toolbar class="home" color="primary">
        <ion-title>{{ lang?.menu_bar?.home ?? "Home" }}</ion-title>
        <ion-buttons slot="end">
          <select-language></select-language>
          <ion-button router-link="/settings" v-if="!browser">
            <ion-icon slot="icon-only" :icon="settingsSharp"></ion-icon>
          </ion-button>
          <toolbar-download-button v-else></toolbar-download-button>
        </ion-buttons>
      </ion-toolbar>
      <slot name="segment"></slot>
    </ion-header>
    <ion-content overflow-scroll="true" color="transparent">
      <div class="curve-bars">
        <div class="light-bar"></div>
        <div class="dark-bar"></div>
      </div>
      <div
        id="home-page-container"
        class="page-container ion-padding home-scroll"
      >
        <!-- App install call to action banner for web only -->
        <download-banner
          v-if="browser"
          type="info"
          :addonClasses="'not-in-list'"
          :title="lang.change ?? 'Profit more on the Uptrade app'"
          :text="
            lang.change ??
            'Receive instant notifications for new signals, buy and sell at the right moment, add signals to favorites, and more when you use our signals via the Uptrade app.'
          "
          name="cta_banner_home"
        ></download-banner>

        <div id="rate-us" @click="rateUs()" v-if="showRateUs && !browser">
          <div class="icon">
            <img :src="star" alt="" />
          </div>

          <div class="descr">
            <div class="title">{{ lang.home.rate_us.title }}</div>
            <p class="text">
              {{ lang.home.rate_us.description }}
              <ion-icon :icon="heart" class="love"></ion-icon>
            </p>
          </div>

          <ion-icon
            :icon="close"
            class="close"
            @click="hideRateUs($event)"
          ></ion-icon>
        </div>

        <div
          id="fear-nd-greed-wrap"
          :style="'height: ' + fearGreedHeight + 'px;'"
        >
          <div id="fear-nd-greed" :class="{ ready: fearGreedImg }">
            <img :src="fearGreedImg" alt="" />
          </div>
          <ion-icon :icon="speedometerOutline"></ion-icon>
        </div>

        <div id="tech_analysis_wrap_home">
          <div
            id="tech_analysis_home"
            ref="tech_analysis_home"
            v-if="ready"
            :class="{ ready: techReady }"
          ></div>
          <ion-icon :icon="analyticsOutline"></ion-icon>
        </div>

        <div id="screener_wrap" :style="'height: ' + screenerHeight + 'px;'">
          <div
            id="screener"
            ref="screener"
            v-if="ready"
            :class="{ ready: screenerReady }"
          ></div>
          <ion-icon :icon="analyticsOutline"></ion-icon>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>


<script>
// import {defineAsyncComponent} from "vue";
import {
  IonButton,
  IonIcon,
  IonPage,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonContent,
  IonButtons,
} from "@ionic/vue";
import {
  close,
  heart,
  analyticsOutline,
  speedometerOutline,
  settingsSharp,
  search,
  cloudDownload
} from "ionicons/icons";
import star from "../../assets/img/star.png";
import SelectLanguage from "@/views/components/UI components/SelectLanguage";
import DownloadBanner from '../components/cat-3/DownloadBanner.vue';
import ToolbarDownloadButton from '../components/UI components/ToolbarDownloadButton.vue';

export default {
  inject: ["store"],
  data() {
    return {
      star,
      close,
      heart,
      settingsSharp,
      search,
      analyticsOutline,
      speedometerOutline,
      ready: false,
      lastScrollTop: 0,
      screenerHeight: 0,
      fearGreedHeight: 0,
      techReady: false,
      screenerReady: false,
      fearGreedImg: null,
      showRateUs: true,
    };
  },
  components: {
    IonButton,
    IonIcon,
    IonPage,
    IonHeader,
    IonTitle,
    IonContent,
    IonToolbar,
    IonButtons,
    SelectLanguage,
    DownloadBanner,
    ToolbarDownloadButton
  },

  computed: {
    cta_banner_actions() {
      // let self = this;
      return [
        {
          text: "Download The App",
          icon: cloudDownload,
          handler() {
            alert("opening app");
          },
        },
      ];
    },
  },

  methods: {
    setFearGreed() {
      let self = this;
      var highResImage = new Image();

      highResImage.onload = function () {
        self.fearGreedImg = highResImage.src;
      };

      highResImage.onerror = function () {
        setTimeout(self.setFearGreed(), 1000);
        console.log("Error loading fear and greed index image");
      };

      let timestamp = new Date().getTime();
      highResImage.src =
        "https://alternative.me/crypto/fear-and-greed-index.png" +
        "?t=" +
        timestamp;
    },

    init() {
      let self = this;
      self.ready = true;
      self.$nextTick(() => {
        //READY FEAR AND GREED INDEX IMAGE
        self.setFearGreed();

        //READY TECHNICAL ANALYSIS
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src =
          "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
        script.textContent = JSON.stringify({
          interval: "1m",
          width: "100%",
          isTransparent: false,
          height: "100%",
          symbol: "BTCUSDT",
          showIntervalTabs: true,
          locale: self.tradingViewLocale,
          colorTheme: "light",
        });

        let techAnalysisView = document.getElementById("tech_analysis_home");
        techAnalysisView.appendChild(script);

        self.techReady = true;

        //READY SCREENER
        script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src =
          "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
        script.textContent = JSON.stringify({
          width: "100%",
          height: "100%",
          defaultColumn: "overview",
          defaultScreen: "top_gainers",
          market: "crypto",
          showToolbar: true,
          colorTheme: "light",
          locale: self.tradingViewLocale,
        });

        let screenerView = document.getElementById("screener");
        screenerView.appendChild(script);

        self.screenerReady = true;
      });
    },

    async rateUs() {
      await this.store.setters.reviews({
        reviewed: true,
      });
      window.location.href = this.appStoreUrl;
    },

    hideRateUs(e) {
      e.stopPropagation();
      this.showRateUs = false;
    },
  },

  ionViewDidEnter() {
    this.init();
  },

  ionViewWillLeave() {
    // this.$refs.signalList.$refs.recycler.parentViewWillLeave();
  },

  ionViewDidLeave() {
    this.ready = false;
    this.techReady = false;
    this.screenerReady = false;
  },

  created() {
    //Set Chart height
    this.screenerHeight = window.innerHeight - 118;

    //Set fear greed height
    this.fearGreedHeight = (window.innerWidth - 32) * (575 / 640);
  },
};
</script>


<style scoped lang="scss">
.page-container {
  padding-top: 20px;
  border-top-left-radius: 40px;
  background: hsla(240, 25%, 98.4%, 0.99);
  overflow: auto;
  position: relative;
  @include toolbar-content-size-1;
}

ion-fab.home-ion-fab {
  position: fixed;
  left: calc(50% - 28px);
  top: 100px;
  width: fit-content;
  height: fit-content;

  ion-icon {
    color: var(--ion-color-secondary-contrast);
  }
}

.no-result {
  width: 100%;
  height: 100%;
  // background: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0px;

  .image {
    width: 50%;
    margin-bottom: 50px;
  }

  p {
    position: relative;
    margin-bottom: 56px;
    margin-top: 10px;
    color: #888;

    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: #dedede;
      position: absolute;
      bottom: -29px;
      left: 0;
    }
  }

  h4 {
    font-weight: 800;
  }
}

#rate-us {
  display: flex;

  .icon {
    width: 40px;
    margin-right: 10px;
  }

  .descr {
    .title {
      font-weight: 600;
    }

    .text {
      font-size: calc(13px + $extra-font-size);
      margin-top: 4px;

      .love {
        color: red;
        position: relative;
        top: 2px;
      }
    }
  }

  ion-icon.close {
    font-size: calc(20px + $extra-font-size);
  }
}

#fear-nd-greed-wrap {
  background: hsl(0, 0%, 95.3%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 30px;
  overflow: hidden;

  ion-icon {
    color: hsl(0, 0%, 90.3%);
    font-size: calc(80px + $extra-font-size);
  }

  #fear-nd-greed {
    width: 100%;
    height: 100%;
    position: absolute;

    img {
      width: 100%;
    }

    &.ready {
      z-index: 1;
    }
  }
}

#tech_analysis_wrap_home {
  background: hsl(0, 0%, 95.3%);
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
  border-radius: 30px;
  overflow: hidden;

  ion-icon {
    color: hsl(0, 0%, 90.3%);
    font-size: calc(80px + $extra-font-size);
  }

  #tech_analysis_home {
    width: 100%;
    height: 100%;
    position: absolute;

    &.ready {
      z-index: 1;
    }
  }
}

#screener_wrap {
  background: hsl(0, 0%, 95.3%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
  border-radius: 30px;
  overflow: hidden;

  ion-icon {
    color: hsl(0, 0%, 90.3%);
    font-size: calc(80px + $extra-font-size);
  }

  #screener {
    width: 100%;
    height: 100%;
    position: absolute;

    &.ready {
      z-index: 1;
    }
  }
}
</style>