// items-provider.js
import notificationSound from "./assets/sounds/notification-inapp.mp3";

import {
    reactive,
    readonly
} from "vue";

import {
    Plugins, StatusBarStyle,
} from '@capacitor/core';

const sound = new Audio(notificationSound);
const appVersionStr = "1.0.1";

const {
    Storage,
    Toast,
    Device,
    PushNotifications,
    App,
    NativeHelper,
    StatusBar,
} = Plugins;

const plugins = {
    Storage,
    Toast,
    Device,
}

var emitter = null;
var appInstance = null;

const state = reactive({
    checkedAppVersion: false,
    appReady: false,
    token: null,
    logout: null,
    resetRouter: null,
    user: null,
    deviceID: null,
    firebase_token: null,
    signalInView: null,
    generalNotificationInView: null,
    newsInView: null,
    keyboard: false,
    signalsPage: {
        funnelAnimation: true,
    },
    explainAnimation: true,
    reviews: {
        appResumeCount: 0,
        deniedReview: false,
        reviewed: false,
    },
    loader: {
        show: true,
        loading: true,
    },
    signalFilter: null,
    deviceData: {
        firebase_token: null,
        deviceID: null,
        description: null,
        osVersion: null,
        platform: null
    },
    pageAnimation: false,
    currentRoute: null,
    compActionTrigger: false,
    compAction: null,
    reloadOnEnter: { //Flags to reload pages on enter
        signal: null, //If this is set to a signal, we should look at the signal's type, and reload the 'all' slide and the related slide.
        notifications: false, //If this is set to a true, we reload the notification page on enter,
        future_signal: false //If this is set to a futures signal, we ...
    },
    newItems: { //Notification flags for new items are stored here when the relevant component is not in view. So it can attend to them when the component is entered
        signal: null,
        future_signal: null,
    },
    enteredFromTap: false,
    searchType: "signals",
    overlayPermission: null,
    notificationPermission: true,
    notificationsSet: false,
    settings: null,
    guideMode: false, //True when a tour guide is happening. Helps to alert App.vue to disable back button to avoid breaking.
    banners: { //Keeps track of all info banners
        "all_spots": true,
        "all_futures": true,
        "cta_banner_spots": true,
        "cta_banner_futures": true,
        "cta_banner_news": true,
        "cta_banner_home": true,
        "notification_permission_banner": false,
        "news_notifications": {
            status: false, //The visibility of the banner while hide_forever is false
            hide_forever: false //If true, the banner never shows regardless of visiblity
        },
    }
});

const getters = {
    isGuest() {
        return state.guestMode.status;
    },

    //Get device language
    async defaultLang() {
        try {
            let result = await NativeHelper.getSystemLanguage();
            let defaultLang = result.value;

            //If the device default language is not on our list, use english
            if (!window.lang[defaultLang]) {
                defaultLang = "en";
            }

            return defaultLang;
        } catch (e) {
            //Use english as default language if we fail to get it
            return "en";
        }

    },

    guestNotificationSettings() {
        return state.guestMode.notifications;
    },

    deviceID() {
        return state.deviceID;
    },

    authAction() {
        return state.auth.action;
    },

    signalInView() {
        return state.signalInView;
    },

    userToken() {
        return state.token;
    },

    pageAnimation() {
        return state.pageAnimation
    },

    async clipboardWorks() {
        let result = true;

        try {
            if (!await actions.isClipCompliant()) {
                let clipboardStatus = await NativeHelper.getOverlayPermissionStatus();
                result = clipboardStatus.result
            }
        } catch (e) {
            console.log(e)
        }

        return result;
    },

    async upToDate() {

        //MOBILE_ONLY_FUNCTION.
        if (state.deviceData.platform == "web") {
            return true;
        }

        // let info = await Device.getInfo();
        let info = { appVersion: appVersionStr };
        let upToDate = true;
        try {
            var resp = await window.axios({
                url: endpoint + "/get-current-apk-version",
                method: "get",
            });

        } catch (err) {
            console.log(err);
        }

        if (resp.status == 200) {
            let acceptedVersions = [info.appVersion, "7.0.0"]; //Include old version since server side returns 7.0.0 for recent version to work. when update fully launched, we can safely change app version to 1.0.1 (current version)
            if (acceptedVersions.indexOf(resp.data) == -1) {
                upToDate = false;
            }
        }

        if (!upToDate) {
            actions.deactivateNotifications();
            await Storage.clear();

            //Set first_launch to prevent user from having to go through welcome screen before seeing update request on next run.
            await Storage.set({
                key: "first_launch",
                value: "true"
            });

            //Set app_version to prevent user from having to go through welcome screen before seeing update request on next run.
            await Storage.set({
                key: "app_version",
                value: appVersionStr
            });

            let clipboardWorks = await getters.clipboardWorks();

            if (!clipboardWorks) {
                await Storage.set({
                    key: "overlayPermission",
                    value: "true"
                });
            }
        }

        state.checkedAppVersion = true;

        return upToDate;

    },

    async firstRoute() {

        let route = "/welcome";

        let result = await Storage.get({
            key: "first_launch"
        });

        if (result.value) {
            route = "/allow-draw";

            let clipboardWorks = await this.clipboardWorks();

            if (clipboardWorks) {
                route = "/update-app";
                let upToDate = await getters.upToDate();

                if (upToDate) {
                    route = "/spots";

                    if (state.signalInView) {
                        route = "/signal";
                    }

                    if (state.newsInView) {
                        route = "/news-update";
                    }

                    if (state.generalNotificationInView) {
                        route = "/general-notification";
                    }
                }
            } else {
                if (state.overlayPermission == "true") {

                    state.overlayPermission = "false";

                    await Storage.set({
                        key: "overlayPermission",
                        value: "false"
                    });

                }
            }
        }

        //If we're accesing from web, first page always Spots
        if (state.deviceData.platform == "web") {
            route = "/spots"
        }

        return route;

    }
}

const setters = {
    generalNotificationInView(value) {
        state.generalNotificationInView = value;
    },

    setNewItem(key, val) {
        state.newItems[key] = val
    },

    setBanner(name, value) {
        state.banners[name] = value;
    },

    guideMode(bool) {
        state.guideMode = bool
    },

    async funnelAnimation(bool) {
        state.signalsPage.funnelAnimation = bool;
        await Storage.set({
            key: "funnelAnimation",
            value: bool ? "true" : "false"
        });
    },

    async explainAnimation(bool) {
        state.explainAnimation = bool;
        await Storage.set({
            key: "explainAnimation",
            value: bool ? "true" : "false"
        });
    },

    async notificationsSet(bool) {
        state.notificationsSet = bool;
        await Storage.set({
            key: "notificationsSet",
            value: bool ? "true" : "false"
        });
    },

    async applySettings(settings) {
        state.settings = settings

        await Storage.set({
            key: "settings",
            value: JSON.stringify(settings)
        });

    },

    async overlay(obj) {
        if (obj.deniedBefore) {
            state.overlay.deniedBefore = obj.deniedBefore;
        }

        if (obj.grantedBefore) {
            state.overlay.grantedBefore = obj.grantedBefore;
        }

        await Storage.set({
            key: "overlay",
            value: JSON.stringify(state.overlay)
        });
    },

    async reviews(obj) {

        if (obj.appResumeCount || obj.appResumeCount === 0) {
            state.reviews.appResumeCount = obj.appResumeCount;
        }

        if (obj.deniedReview) {
            state.reviews.deniedReview = obj.deniedReview;
        }

        if (obj.reviewed) {
            state.reviews.reviewed = obj.reviewed;
        }

        await Storage.set({
            key: "reviews",
            value: JSON.stringify(state.reviews)
        });
    },


    resetRouter(val) {
        state.resetRouter = val;
    },

    async guestSettings(notifications) {
        state.guestMode.notifications = notifications;

        await Storage.set({
            key: "guest_mode_notifications",
            value: JSON.stringify(notifications)
        });

    },

    emitter(val) {
        emitter = val;
    },

    appInstance(val) {
        appInstance = val;
    },

    logout(val) {
        state.logout = val;
    },

    keyboard(val) {
        state.keyboard = val;
    },

    searchType(type) {
        state.searchType = type;
    },

    authAction(action) {
        state.auth.action = action;
    },

    signalInView(signal) {
        state.signalInView = signal;
    },

    newsInView(news) {
        state.newsInView = news;
    },

    updateSignalInView(obj) {
        state.signalInView.data = {
            ...state.signalInView.data,
            ...obj
        };
    },

    pageAnimation(bool) {
        state.pageAnimation = bool;
    },

    currentRoute(_route) {
        state.currentRoute = _route;
    },

    reloadOnEnter(key, bool) {
        state.reloadOnEnter[key] = bool;
    },

    appReady(bool) {
        state.appReady = bool;
    },

    signalFilter(filter) {
        state.signalFilter = filter;
    }
}

const actions = {
    //When a new visitor loads the webapp on a browser
    async newVisitor() {
        let newVisitor = await Storage.get({
          key: "new_web_visitor",
        });
  
        if (!newVisitor.value) {
          await this.registerWebEvent("New visitor");
  
          Storage.set({
            key: "new_web_visitor",
            value: "true",
          });
        } else {
          await this.registerWebEvent("Revisited/Reloaded Page");
        }
      },

    //Register an event performed on the webapp
    async registerWebEvent(description) {
        let data = {
            description,
            device_id: state.deviceID,
        };

        try {
            await window.axios({
                url: endpoint + "/register-web-event",
                method: "post",
                data,
            });
        } catch (err) {
            console.log(err);
        }
    },

    async syncNotificationPermissionStatus(banner = false) { //If banner is true, then this sync will affect banners visibility via it's state value as well.
        let status = await NativeHelper.getNotificationPermissionStatus();

        if (!status.result) {
            state.notificationPermission = false;
            if (banner) {
                state.banners.notification_permission_banner = true;
            }
        } else {
            state.notificationPermission = true;
            state.banners.notification_permission_banner = false;
        }
    },

    //Syncnorize app language with device language
    //if app language is set to the 'use device language' option
    async syncLocale() {
        if (state.settings?.systemLangOption) {
            state.settings.lang = await getters.defaultLang();
            setters.applySettings(state.settings)
        }
    },

    async deactivateNotifications() {

        // MOBILE_ONLY_FUNCTION
        if (state.deviceData.platform == "web") {
            return true;
        }

        try {
            await window.axios({
                url: endpoint + "/deactivate-notifications",
                method: "post",
                data: {
                    firebase_token: state.firebase_token,
                    general_notifications: true
                },
            });
        } catch (e) {
            console.log(e);
        }
    },

    async subscribeToDefaultTopics() {

        //MOBILE_ONLY_FUNCTION
        if (state.deviceData.platform == "web") {
            return true;
        }

        let subscribed = true;

        try {
            await window.axios({
                url: endpoint + "/set-default-notifications",
                method: "post",
                data: {
                    firebase_token: state.firebase_token,
                    general_notifications: true
                }
            });

        } catch (err) {
            console.log(err);
            subscribed = false;
        }

        return subscribed;

    },

    async setStatusBarStyleDark() {
        await StatusBar.setStyle({ style: StatusBarStyle.Dark });
    },

    async setStatusBarStyleLight() {
        await StatusBar.setStyle({ style: StatusBarStyle.Light });
    },

    async respondToAppResumeCount(appResumeCount) {
        if (appResumeCount >= 5) {
            appResumeCount = 0;
            appInstance.getReview();
        }

        await setters.reviews({
            appResumeCount
        });
    },

    async isClipCompliant() {
        let info = await Device.getInfo();
        let versionStr = info.osVersion;
        let reg = /\d+/;
        let versionInt = Number(versionStr.match(reg)[0]);
        if (!versionInt || versionInt <= 9) {
            return true;
        } else {
            return false;
        }
    },

    openSearch(searchType) {
        state.searchType = searchType
        actions.triggerCompAction("navigate", {
            route: "/search",
        });
    },

    triggerCompAction(type, data) {
        state.compAction = {
            type, data
        }
        state.compActionTrigger = !state.compActionTrigger;
    },

    //Account for all the notification types currently in the notification tray
    refreshLists(lists) {
        /* 
        
        lists = {
            news: true //Refresh news
            spots: true //refresh home
            reload: true //reload current affected page
        }
        
        */
        let showBadge = {};

        if (lists.reload) {
            if (lists.spots) {
                switch (state.currentRoute.name) {
                    case "Spots":
                        //We're in the associated notification type's page, so notify the page.

                        actions.triggerCompAction("new_spot", { type: "unknown" });//The spots page will be expecting an object with a 'type' key. We'll handle its value there.

                        break;
                    default:
                        //We're not, so we indicate there is a new item for the this page to react to when it is entered

                        state.newItems.signal = { type: "spots" }

                        showBadge.spots = true;
                        break;
                }
            }


            if (lists.futures || lists.high_leverage) {
                switch (state.currentRoute.name) {
                    case "Futures":
                        //We're in the associated notification type's page, so notify the page.

                        if (lists.futures) {
                            actions.triggerCompAction("new_future", { type: "futures" });
                        }//The Futures page will be expecting an object with a 'type' key. We'll handle its value there.


                        if (lists.high_leverage) {
                            actions.triggerCompAction("new_future", { type: "high_leverage" });
                        }

                        break;
                    default:
                        //We're not, so we indicate there is a new item for the this page to react to when it is entered

                        state.newItems.future_signal = { type: "unknown" }

                        showBadge.futures = true;
                        break;
                }
            }


            if (lists.news) {
                switch (state.currentRoute.name) {
                    case "News":
                        actions.triggerCompAction("news_update", null);
                        break;
                    default:
                        state.reloadOnEnter.news = true;
                        showBadge.news = true;
                        break;
                }
            }

            if (lists.spots_target_update || lists.futures_target_update || lists.high_leverage_target_update || lists.general_notification) {
                switch (state.currentRoute.name) {
                    case "Notifications":
                        actions.triggerCompAction("new_notification", null);
                        break;
                    default:
                        state.reloadOnEnter.notifications = true;
                        showBadge.notifications = true;
                        break;
                }

                if (lists.spots_target_update) {
                    actions.triggerCompAction("spots_target_update", null);
                } else if (lists.futures_target_update || lists.high_leverage_target_update) {
                    actions.triggerCompAction("futures_target_update", { type: "unknown" });
                } `  1`
            }


        } else {
            if (lists.spots) {
                state.reloadOnEnter.signal = { type: "unknown" };
                showBadge.spots = true;
            }

            if (lists.notifications) {
                state.reloadOnEnter.notifications = true;
                showBadge.notifications = true;
            }

            if (lists.news) {
                state.reloadOnEnter.news = true;
                showBadge.news = true;
            }
        }

        emitter.emit("reflect_notifications", showBadge);
    },

    //Attend to all notifications in the notification tray.
    async handleBackgroundDeliveredNotifs() {
        //Coming
        let result = await PushNotifications.getDeliveredNotifications();
        let notifications = {};

        let inRange = (number, min, max) => {
            return (number >= min) && (number <= max)
        }

        result.notifications.forEach((notification) => {
            let id = notification.id;

            if (inRange(id, 2, 1000000)) {
                notifications.news = true;
            } else if (inRange(id, 1000001, 2000000)) {
                notifications.spots = true;
            } else if (inRange(id, 2000001, 3000000)) {
                notifications.spots_target_update = true;
            } else if (inRange(id, 3000001, 4000000)) {
                notifications.futures = true;
            } else if (inRange(id, 4000001, 5000000)) {
                notifications.futures_target_update = true;
            } else if (inRange(id, 5000001, 6000000)) {
                notifications.high_leverage = true;
            } else if (inRange(id, 6000001, 7000000)) {
                notifications.high_leverage_target_update = true;
            } else if (inRange(id, 7000001, 8000000)) {
                notifications.general_notification = true;
            }
            //  else if (inRange(id, 7000001, 8000000)) {
            //     notifications.spots = true;
            // }
            //  else if (inRange(id, 2000001, 3000000)) {
            //     notifications.notifications = true;
            // }
        });


        PushNotifications.removeAllDeliveredNotifications();

        //If there is at least one notification in the tray, or we entered the app by tapping on one
        if (Object.keys(notifications).length > 0 || state.enteredFromTap) {
            //if we entered the app by tapping on one. We know for sure 
            //That we're going to the signal page or new page, so there's no need for any
            //page to immediately react
            console.log({ fromTap: state.enteredFromTap });
            if (state.enteredFromTap) {
                state.enteredFromTap = false;
                this.refreshLists(notifications);
                return;
            }


            if (Object.keys(notifications).length > 0) {
                sound.play();
            }

            //We don't know what page is currently opened in the app
            //So prepare for ANYTHING.
            notifications.reload = true;

            this.refreshLists(notifications);
        }
    },

    async notifyInstall(deviceID) {

        //MOBILE_ONLY_FUNCTION
        if (state.deviceData.platform == "web") {
            return true;
        }

        try {
            await window.axios({
                url: endpoint + "/new-install",
                method: "post",
                data: {
                    deviceID
                }
            });

        } catch (err) {
            console.log(err);
        }

    },

    async notifyOverlayGrant() {

        try {
            await window.axios({
                url: endpoint + "/allowed-overlay",
                method: "post",
                data: {
                    deviceID: state.deviceID
                }
            });

        } catch (err) {
            console.log(err);
        }

    },


    async buttonExited() {

        try {
            await window.axios({
                url: endpoint + "/denied-overlay",
                method: "post",
                data: {
                    deviceID: state.deviceID
                }
            });

        } catch (err) {
            console.log(err);
        }

    },

    async backExitedFromAllowDraw() {

        try {
            await window.axios({
                url: endpoint + "/back-exit-from-allow-draw",
                method: "post",
                data: {
                    deviceID: state.deviceID
                }
            });

        } catch (err) {
            console.log(err);
        }

    },


}

const server = "https://as100.upchainsignals.com";
// const server = "http://192.168.0.108:8000";
// const server = "http://192.168.137.1:8000";
// const server = "http://localhost:8000";


const endpoint = server + "/api";
const connectionCheckURL = server + "/api/check-connection";
const guidePage = server + "/guide?client=app";

async function test() {
    try {
        let registered = await requestBool("/register-device", {
            method: "post",
            data: {
                "deviceID": "123456789012345678901234567890",
                "firebase_token": "fsgfsgsgf",
                "description": "test device",
            },
        });

        console.log(registered);
    } catch (err) {
        console.log(err);
    }
}

async function initNotificationHandlers() {

    PushNotifications.addListener("pushNotificationReceived", async (payload) => {

        if (payload.data.notification_type) {
            sound.play();
        }

        switch (payload.data.notification_type) {
            case "SPOTS_NEW_SIGNAL":
                var signal = JSON.parse(payload.data.signal);
                switch (state.currentRoute.name) {
                    case "Spots":
                        actions.triggerCompAction("new_spot", signal);
                        break;
                    default:
                        // state.reloadOnEnter.all_spots = true;
                        state.newItems.signal = signal;
                        actions.triggerCompAction("new_signal_outside_spots", null);
                        break;
                }
                break;

            case "FUTURES_NEW_SIGNAL":
            case "HIGH_LEVERAGE_NEW_SIGNAL":
                signal = JSON.parse(payload.data.signal);
                switch (state.currentRoute.name) {
                    case "Futures":
                        actions.triggerCompAction("new_future", signal);
                        break;
                    default:
                        // state.reloadOnEnter.all_spots = true;
                        state.newItems.future_signal = signal;
                        actions.triggerCompAction("new_signal_outside_futures", null);
                        break;
                }
                break;

            case "SPOTS_TARGET_UPDATE": //If it's a spot sell signal
            case "FUTURES_TARGET_UPDATE": //If it's a futures sell signal
            case "HIGH_LEVERAGE_TARGET_UPDATE": //If it's a high leverage sell signal
            case "GENERAL_NOTIFICATION":
                if (payload.data.signal) {
                    signal = JSON.parse(payload.data.signal);
                }
                switch (state.currentRoute.name) {
                    case "Notifications":
                        actions.triggerCompAction("new_notification", null);
                        break;
                    default:
                        state.reloadOnEnter.notifications = true;

                        //We're avoiding using 'triggerCompAction()' here because we might need to use it below if current route is in Spots page. Using it twice nullifies its effect for both tries. So we're using emitter instead. Which makes more sense honestly and should just be the only form of communication in future builds.
                        emitter.emit("new_notification_outside_notifications");
                        break;
                }

                switch (payload.data.notification_type) {
                    case "SPOTS_TARGET_UPDATE":
                        actions.triggerCompAction("spots_target_update", signal);
                        break;

                    case "FUTURES_TARGET_UPDATE":
                    case "HIGH_LEVERAGE_TARGET_UPDATE":
                        actions.triggerCompAction("futures_target_update", signal);
                        break;
                }




                break;

            case "NEWS_UPDATE":
                switch (state.currentRoute.name) {
                    case "News":
                        actions.triggerCompAction("news_update", null);
                        break;
                    default:
                        state.reloadOnEnter.news = true;
                        actions.triggerCompAction("news_update_outside_news", null);
                        break;
                }
                break;
        }
    });

    PushNotifications.addListener("pushNotificationActionPerformed", async (payload) => {

        let notification_type = payload.notification.data.notification_type;
        state.enteredFromTap = true;

        /* if (notification_type == "BUY_SIGNAL") {
            
        } else if (notification_type == "UPDATED_SIGNAL") {
            state.reloadOnEnter.watched = true;
        } */
        var signal;
        var notification;

        switch (notification_type) {
            case "SPOTS_NEW_SIGNAL":
                signal = JSON.parse(payload.notification.data.signal);
                state.reloadOnEnter.signal = signal;
                state.signalInView = { data: signal, key_: 0 };

                if (state.appReady) {
                    //If we're currently viewing in the signal view page
                    if (state.currentRoute.name == "Signal") {
                        //Reinitialize the page
                        actions.triggerCompAction("reinitialize", {
                            route: "/signal"
                        });
                    } else {
                        //Navigate to the page
                        actions.triggerCompAction("navigate", {
                            route: "/signal",
                        });
                    }
                } else {
                    signal.openedOnLaunch = true;
                }
                break;

            case "FUTURES_NEW_SIGNAL":
            case "HIGH_LEVERAGE_NEW_SIGNAL":
                signal = JSON.parse(payload.notification.data.signal);
                state.reloadOnEnter.future_signal = signal;
                state.signalInView = { data: signal, key_: 0 };

                if (state.appReady) {
                    //If we're currently viewing in the signal view page
                    if (state.currentRoute.name == "Signal") {
                        //Reinitialize the page
                        actions.triggerCompAction("reinitialize", {
                            route: "/signal"
                        });
                    } else {
                        //Navigate to the page
                        actions.triggerCompAction("navigate", {
                            route: "/signal",
                        });
                    }
                } else {
                    signal.openedOnLaunch = true;
                }
                break;

            case "SPOTS_TARGET_UPDATE": //If it's a spot sell signal
            case "FUTURES_TARGET_UPDATE": //If it's a futures sell signal
            case "HIGH_LEVERAGE_TARGET_UPDATE": //If it's a high leverage sell signal
                signal = JSON.parse(payload.notification.data.signal);
                state.reloadOnEnter.notifications = true;

                switch (notification_type) {
                    case "SPOTS_TARGET_UPDATE":
                        // state.reloadOnEnter.signal = signal;
                        emitter.emit("spots_target_update");
                        break;
                    case "FUTURES_TARGET_UPDATE":
                    case "HIGH_LEVERAGE_TARGET_UPDATE":
                        state.reloadOnEnter.future_signal = signal;
                        break;
                }

                state.signalInView = { data: signal, key_: 0 };

                if (state.appReady) {
                    //If we're currently viewing in the signal view page
                    if (state.currentRoute.name == "Signal") {
                        //Reinitialize the page
                        actions.triggerCompAction("reinitialize", {
                            route: "/signal"
                        });
                    } else {
                        //Navigate to the page
                        actions.triggerCompAction("navigate", {
                            route: "/signal",
                        });
                    }
                } else {
                    signal.openedOnLaunch = true;
                }
                break;

            case "GENERAL_NOTIFICATION":
                notification = JSON.parse(payload.notification.data.notification);
                state.generalNotificationInView = notification;
                state.reloadOnEnter.notifications = true;

                if (state.appReady) {
                    //Navigate to the general-notification page to view it
                    actions.triggerCompAction("navigate", {
                        route: "/general-notification",
                    });
                } else {
                    //This way the notification view knows this was opened on launch
                    //Good for making decisions such as going back
                    notification.openedOnLaunch = true;
                }
                break;

            case "NEWS_UPDATE":
                var news_item = JSON.parse(payload.notification.data.news_item);

                state.newsInView = news_item;

                if (state.appReady) {
                    actions.triggerCompAction("navigate", {
                        route: "/news-update",
                    });
                } else {
                    news_item.openedOnLaunch = true;
                }

                state.reloadOnEnter.news = true;
                break;
        }

        // actions.refreshLists();
    });


}


async function initApp() {

    //Make necessary cache adjustments if app was just updated (i.e version installed does not exist in cache or this is app first launch)
    await (async () => {
        let appVersion = await Storage.get({
            key: "app_version"
        });

        if (appVersion.value != appVersionStr) {
            Storage.clear();
            Storage.set({
                key: "app_version",
                value: appVersionStr
            });
        }
    })();

    //Listen to state change
    App.addListener("appStateChange", async ({ isActive }) => {
        if (isActive) {
            //Handle delivered notifications
            store.actions.handleBackgroundDeliveredNotifs();

            //Check for language change
            actions.syncLocale();

            //Sync Notification permission status
            actions.syncNotificationPermissionStatus();

            //Match privileges with overlay status
            if (!await actions.isClipCompliant()) {
                (async () => {
                    try {
                        let status = await NativeHelper.getOverlayPermissionStatus();
                        if (status.result) {
                            if (state.overlayPermission != "true") {

                                //LATERS
                                // if (state.overlayPermission == "false") {
                                //     //Notify that overlay permission re-granted
                                // } else {
                                //     store.actions.notifyOverlayGrant();
                                // }

                                store.actions.notifyOverlayGrant();

                                await Storage.set({
                                    key: "overlayPermission",
                                    value: "true"
                                });

                                state.overlayPermission = "true";

                                emitter.emit("overlay-is-on");
                            }
                        }
                    } catch (e) {
                        console.log(e)
                    }
                    /*
                    else if overlay permission is 'true' notify of overlay
                    permission turned off after grant. Set overlay permission
                    to 'false' in storage and state. Then navigate user to
                    allow draw page.
                    */
                })();
            }
        }
    });

    //Initialize filter
    await (async () => {
        let signalFilter = await Storage.get({
            key: "signal_filter"
        });
        settings
        if (signalFilter.value) {
            state.signalFilter = JSON.parse(signalFilter.value);
        } else {
            signalFilter = {
                taken_profit: false,
                not_taken_profit: false,
                low_risk: false,
                medium_risk: false,
                high_risk: false,
                below_entry: false
            }
            state.signalFilter = signalFilter;

            await plugins.Storage.set({
                key: "signal_filter",
                value: JSON.stringify(signalFilter)
            });
        }

    })();

    //Initialize settings
    let settings = await Storage.get({
        key: "settings"
    });

    if (settings.value) {
        state.settings = JSON.parse(settings.value);
        actions.syncLocale();
    } else {
        settings = {
            // lang: await getters.defaultLang(), //Using device language by default
            lang: "en", //Using english by default
            systemLangOption: false, //Not using systemLanguage by default
            notifications: {
                signals: true,
                news: false,
            }
        }

        state.settings = settings;
        await plugins.Storage.set({
            key: "settings",
            value: JSON.stringify(settings)
        });
    }

    initNewsNotifBanner();

    //Initialize overlayPermission
    await (async () => {
        let overlayPermission = await Storage.get({
            key: "overlayPermission"
        });

        if (overlayPermission.value) {
            state.overlayPermission = overlayPermission.value;
        }
    })();


    //Set device platform
    await setDevicePlatform();

    return new Promise((resolve, reject) => {

        (async () => {

            initNotificationHandlers();

            let setup = () => {
                setupNotifications()
                    .then(() => {
                        return store.registerDevice();
                    })
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }

            let deviceID = await Storage.get({
                key: "deviceID"
            });

            if (deviceID.value) {

                try {
                    var deviceIsRegistered = await window.axios.get(endpoint + "/verify-device/" + deviceID.value);
                } catch (err) {
                    reject({
                        status: false,
                        type: "network-error",
                        error: err
                    });
                }

                if (deviceIsRegistered.data) {
                    let firebase_token = await Storage.get({
                        key: "firebase_token"
                    });

                    state.firebase_token = firebase_token.value;
                    state.deviceID = deviceID.value;

                    await setDeviceData(state.deviceID);

                    //ENSURE DEVICE IS REGISTERED FOR NOTIFICATIONS
                    let notificationsSet = await Storage.get({
                        key: "notificationsSet"
                    });

                    if (notificationsSet.value) {
                        state.notificationsSet = notificationsSet.value;
                        resolve();
                    } else {
                        setupNotifications().then(() => {
                            setters.notificationsSet(true);
                            resolve();
                        })
                    }
                } else {
                    setup();
                }
            } else {
                setup();
            }
        })();

    });
}

async function registerDevice() {
    //Get device id
    //Store both in memory

    let registerationUrl = "/register-device"

    //Register web device instead if platform is a web platform
    if (state.deviceData.platform == "web") {
        registerationUrl = "/register-web-device"
    }

    return new Promise((resolve, reject) => {

        (async () => {

            await setDeviceData();

            let data = state.deviceData;

            let registered = await requestBool(registerationUrl, {
                method: "post",
                data
            });

            if (registered) {

                state.deviceID = state.deviceData.deviceID;
                state.firebase_token = state.deviceData.firebase_token;

                await store.plugins.Storage.set({
                    key: "firebase_token",
                    value: state.firebase_token
                });

                await store.plugins.Storage.set({
                    key: "deviceID",
                    value: state.deviceID
                });

                //Store device ID in sharedPreferences
                try {
                    await NativeHelper.storeInSharedPrefs({
                        key: "tr_deviceID",
                        value: state.deviceID
                    });
                } catch (e) {
                    console.log(e);
                }

                actions.notifyInstall(state.deviceID);

                resolve(registered);
            } else {
                reject({
                    status: false,
                    type: "network-error",
                    err: registered
                });
            }

        })()

    });
}

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function updateDevRegDat(obj) {
    state.deviceData = {
        ...state.deviceData,
        ...obj
    };
}

async function setDeviceData(deviceID = makeid(30)) {
    let info = await Device.getInfo();

    updateDevRegDat({
        description: info.model + " | " + info.osVersion,
        osVersion: info.osVersion,
        deviceID
    });
}


async function requestBool(api, options = {}) {

    /* OPTIONS MAP

    {
        method: request method "post", "get", etc.,
        headers: headers object for axios e.g in the event of token authorization,
        data: data object to be sent in post or get.
    }

    */

    let axiosOptions = {
        url: endpoint + api,
        method: "get",
    }

    if (options.method) {
        axiosOptions.method = options.method;
    }

    if (options.headers) {
        axiosOptions.headers = options.headers;
    }

    if (options.data) {
        axiosOptions.data = options.data;
    }

    try {
        let resp = await window.axios(axiosOptions);
        if (resp.data) {
            return true;
        } else {
            return false;
        }

    } catch (err) {
        return false;
    }
}

//Set up notifications returns a promise
async function setupNotifications() {

    //We don't wanna bother with notifications API if platform is web
    if (state.deviceData.platform == "web") {
        return new Promise((resolve) => {
            resolve({
                success: true,
            });
        })
    }

    return new Promise((resolve) => {

        PushNotifications.requestPermission().then(async (result) => {
            if (result.granted) {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
                // alert("Permission granted");
            } else {
                // alert("Permission not granted");
                console.log({
                    "no-permission-result": result
                });

                await Toast.show({
                    text: "Notifications permission not granted"
                });

                resolve({
                    success: false,
                });
            }

        });

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token) => {

                updateDevRegDat({
                    firebase_token: token.value
                });

                // alert("Registration works");

                resolve({
                    success: true,
                });
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            async (error) => {
                console.log({
                    "registration-error": error
                });

                await Toast.show({
                    text: 'Could not set up notifications'
                });

                // alert("Registration not working");

                resolve({
                    success: false,
                });
            }
        );

    });
}

function loading(status = true) {
    state.loader.status = status;
}

function loader(status = true) {
    state.loader.show = status;
}

function getLoader() {
    return state.loader;
}

//Initialize new notifiations banner status
async function initNewsNotifBanner() {
    let settings = JSON.parse(JSON.stringify(state.settings));
    let banner_status = true;

    if (settings.notifications.news) {
        banner_status = false;
    }

    //Get hide_forever status
    let result = await Storage.get({ key: "hide_news_notification_banner_forever" });
    let hide_forever = true;

    if (!result.value) {
        hide_forever = false;
    }

    setters.setBanner("news_notifications", {
        status: banner_status,
        hide_forever: hide_forever,
    });
}

//Set device platorm
async function setDevicePlatform() {
    let info = await Device.getInfo();
    state.deviceData.platform = info.platform
}

export const store = readonly({
    state,
    getters,
    setters,
    endpoint,
    guidePage,
    emitter,
    server,
    connectionCheckURL,
    requestBool,
    loader,
    loading,
    getLoader,
    registerDevice,
    updateDevRegDat,
    setupNotifications,
    initApp,
    test,
    plugins,
    actions,
});