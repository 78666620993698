import { createRouter, createWebHistory } from '@ionic/vue-router';
import Home from '../views/pages/Home.vue'
// import noMenuRoutes from "../no-menu-routes";



const routes = [
  {
    path: '/',
    redirect: '/welcome'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      navBar: true,
      authorized: true,
      pageAnimation: true
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import("../views/pages/Welcome.vue"),
    meta: {
      navBar: false,
      authorized: false,
      pageAnimation: true
    },
  },

  {
    path: '/allow-draw',
    name: 'AllowDraw',
    component: () => import("../views/pages/AllowDrawOver.vue"),
    meta: {
      navBar: false,
      authorized: false,
      pageAnimation: true
    },
  },

  {
    path: '/signal',
    name: 'Signal',
    component: () => import("../views/pages/Signal.vue"),
    meta: {
      navBar: true,
      authorized: true,
      pageAnimation: true
    },
    props: true
  },

  {
    path: '/news-update',
    name: 'NewsUpdate',
    component: () => import("../views/pages/NewsUpdate.vue"),
    meta: {
      navBar: true,
      authorized: true,
      pageAnimation: true
    },
    props: true
  },

  {
    path: '/spots',
    name: 'Spots',
    component: () => import("../views/pages/Spots.vue"),
    meta: {
      navBar: true,
      authorized: true,
    },
  },

  {
    path: '/futures',
    name: 'Futures',
    component: () => import("../views/pages/Futures.vue"),
    meta: {
      navBar: true,
      authorized: true,
    },
  },

  {
    path: '/search',
    name: 'Search',
    component: () => import("../views/pages/Search.vue"),
    meta: {
      navBar: true,
      authorized: true,
    },
  },

  {
    path: '/news',
    name: 'News',
    component: () => import("../views/pages/News.vue"),
    meta: {
      navBar: true,
      authorized: true,
    },
  },

  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import("../views/pages/Notifications.vue"),
    meta: {
      navBar: true,
      authorized: true,
    },
  },

  {
    path: '/settings',
    name: 'Settings',
    component: () => import("../views/pages/Settings.vue"),
    meta: {
      navBar: true,
      authorized: true,
      pageAnimation: true
    },
  },

  {
    path: '/update-app',
    name: 'UpdateApp',
    component: () => import("../views/pages/UpdateApp.vue"),
    meta: {
      navBar: false,
      authorized: true,
    },
  },

  {
    path: '/general-notification',
    name: 'GeneralNotification',
    component: () => import("../views/pages/GeneralNotification.vue"),
    meta: {
      navBar: true,
      authorized: true,
      pageAnimation: true
    },
  },

  {
    path: '/test',
    name: 'Test',
    component: () => import("../views/tests/recyclist/example/App.vue"),
    meta: {
      navBar: false,
      authorized: true,
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Build middleware for pages: https://markus.oberlehner.net/blog/implementing-a-simple-middleware-with-vue-router/

export default router
