<template>
  <div class="demonstration">
    <toggle-vue></toggle-vue>
  </div>
</template>

<script>
import ToggleVue from "./Toggle.vue";

export default {
  data() {
    return {};
  },

  components: {
    ToggleVue,
  },
};
</script>

<style scoped lang="scss">
.demonstration {
  width: 100%;
  position: relative;
  top: 24px;
}
</style>