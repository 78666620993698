<template>
  <div :class="'alert ' + type + ' ' + addonClasses" v-if="show">
    <div class="icon">
      <ion-icon :icon="icon"></ion-icon>
    </div>
    <div class="content">
      <div>
        <strong v-if="title" class="title">{{ title }}</strong>
        <div v-html="text" class="text"></div>
      </div>

      <div class="footer" v-if="actions">
        <ion-button
          :class="action.class ?? 'solid'"
          size="small"
          v-for="(action, key) in actions"
          :key="key"
          @click="action.handler()"
        >
          <ion-icon
            v-if="action.icon"
            :icon="action.icon"
            slot="start"
          ></ion-icon>
          {{ action.text }}
        </ion-button>
      </div>
    </div>
    <ion-icon :icon="close" class="close" @click.stop="hide($event)"></ion-icon>
  </div>
</template>

<script>
import { IonIcon, IonButton } from "@ionic/vue";
import { close, bulb, warning } from "ionicons/icons";

export default {
  inject: ["store"],
  data() {
    return {
      close,
      bulb,
      warning,
    };
  },

  props: {
    text: {
      default: "",
    },

    addonClasses: {
      default: "",
    },

    title: {
      default: null,
    },

    type: {
      default: "info",
    },

    name: {
      required: true,
    },

    actions: {
      default: null,
    },
  },

  methods: {
    hide() {
      let self = this;

      if (typeof this.store.state.banners[this.name] == "boolean") {
        this.store.setters.setBanner(this.name, false);
      } else {
        this.store.setters.setBanner(this.name, {
          status: false,
          hide_forever: self.store.state.banners[this.name].hide_forever,
        });
      }
    },
  },

  computed: {
    show() {
      return this.store.state.banners[this.name];
    },

    icon() {
      let self = this;
      switch (self.type) {
        case "info":
          return this.bulb;
        case "warning":
        case "danger":
          return this.warning;
        default:
          return this.bulb;
      }
    },
  },

  components: {
    IonIcon,
    IonButton,
  },

  created() {},
};
</script>

<style lang="scss" scoped>
.alert {
  margin-top: 15px;
  padding: 10px;
  font-size: calc(14px + $extra-font-size);
  border-radius: 20px;
  position: relative;
  padding-right: 30px;
  margin-right: 16px;
  margin-left: 16px;
  text-align: left;
  padding-left: 16px;
  display: flex;
  position: relative;
  z-index: 1;

  &.not-in-list {
    margin: 0 0 20px 0;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .footer {
    margin-top: 5px;

    ion-button {
      // text-transform: capitalize;
    }
  }

  &.info {
    color: #{darken($primary-color, 0%)};
    background: #{lighten($primary-color, 83%)};

    .footer {
      ion-button {
        --color: #fff;
        --background: #{darken($primary-color, 0%)};

        &.outline {
          --color: #{darken($primary-color, 0%)};
          --background: transparent;
          --box-shadow: none;
          --border-color: #{darken($primary-color, 0%)};
          --border-width: 1px;
          --border-style: solid;
        }
      }
    }
  }
  &.danger {
    color: #{darken($danger-color, 25%)};
    background: #{lighten($danger-color, 35%)};

    .footer {
      ion-button {
        --color: #fff;
        --background: #{darken($danger-color, 25%)};

        &.outline {
          --color: #{darken($danger-color, 25%)};
          --background: transparent;
          --box-shadow: none;
          --border-color: #{darken($danger-color, 25%)};
          --border-width: 1px;
          --border-style: solid;
        }
      }
    }
  }

  &.warning {
    color: #{darken($warning-color, 25%)};
    background: #{lighten($warning-color, 35%)};

    .footer {
      ion-button {
        --color: #fff;
        --background: #{darken($warning-color, 25%)};

        &.outline {
          --color: #{darken($warning-color, 25%)};
          --background: transparent;
          --box-shadow: none;
          --border-color: #{darken($warning-color, 25%)};
          --border-width: 1px;
          --border-style: solid;
        }
      }
    }
  }

  .icon {
    margin-right: 10px;
    font-size: calc(26px + $extra-font-size);
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: calc(18px + $extra-font-size);
  }

  .title {
    font-size: calc(16px + $extra-font-size);
    display: block;
    margin-bottom: 6px;
  }
}
</style>