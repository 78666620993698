<template>
  <ion-button fill="clear" @click="selectLanguage()" :class="type" id="select-language-btn">
    <ion-icon
      :slot="type == 'toolbar' ? 'icon-only' : ''"
      :icon="globeOutline"
      class="globe-icon"
    ></ion-icon>
    <ion-icon
      v-if="type == 'floating'"
      :icon="chevronDownOutline"
      class="select-indicator-icon"
    ></ion-icon>
  </ion-button>
</template>

<script>
import { IonIcon, IonButton, actionSheetController } from "@ionic/vue";
import { chevronDownOutline, globeOutline } from "ionicons/icons";
import { Plugins } from "@capacitor/core";
const { NativeHelper } = Plugins;

export default {
  inject: ["store"],
  props: {
    type: {
      type: String,
      default: "toolbar",
    },
  },

  components: {
    IonIcon,
    IonButton,
  },

  data() {
    return {
      chevronDownOutline,
      globeOutline,
      actionSheetController,
    };
  },

  methods: {
    async applyLanguage(language) {
      let defaultLang = await this.store.getters.defaultLang();

      let settings = JSON.parse(JSON.stringify(this.store.state.settings));

      if (language == "default") {
        settings.lang = defaultLang;
        settings.systemLangOption = true;
      } else {
        settings.lang = language;
        settings.systemLangOption = false;
      }

      this.store.setters.applySettings(settings);
      await NativeHelper.storeInSharedPrefs({
        key: "appLanguage",
        value: settings.lang,
      });

      // await NativeHelper.setLanguage({
      //   language,
      // });
    },

    async selectLanguage() {
      const actionSheet = await actionSheetController.create({
        header: this.lang.settings.language ?? "Language",
        cssClass: "my-lang-action-sheet",
        buttons: [
          {
            text: this.lang.settings.default ?? "Use this phone's language",
            role: "default",
          },
          { role: "ar", text: "عربي" },
          { role: "bn", text: "বাংলা" },
          { role: "zh", text: "中国人" },
          { role: "en", text: "English" },
          { role: "fr", text: "Français" },
          { role: "de", text: "Deutsch" },
          { role: "hi", text: "हिन्दी" },
          { role: "id", text: "bahasa Indonesia" },
          { role: "it", text: "Italiano" },
          { role: "ja", text: "日本" },
          { role: "ko", text: "한국인" },
          { role: "mr", text: "मराठी" },
          { role: "pt", text: "Português" },
          { role: "ro", text: "Română" },
          { role: "ru", text: "Русский" },
          { role: "es", text: "Español" },
          { role: "tr", text: "Türk" },
          { role: "vi", text: "Tiếng Việt" },
          {
            text: this.lang.settings.cancel ?? "Cancel",
            role: "cancel",
          },
        ],
      });

      await actionSheet.present();

      const { role } = await actionSheet.onDidDismiss();
      if (role != "cancel" && role != "backdrop") {
        this.applyLanguage(role);
      }
    },
  },
};
</script>

<style scoped lang="scss">
ion-button.floating {
  --color: #fff;
  --padding-end: 0;
  --padding-start: 0;

  ion-icon.globe-icon {
    font-size: calc(35px + $extra-font-size);
  }

  ion-icon.select-indicator-icon {
    font-size: calc(16px + $extra-font-size);
  }
}
</style>