<template>
  <ion-button @click="downloadTheApp(null)">
    <ion-icon slot="start" :icon="cloudDownload"></ion-icon>
    Download App
  </ion-button>
</template>

<script>
import { IonIcon, IonButton } from "@ionic/vue";
import { cloudDownload } from "ionicons/icons";

export default {
  inject: ["store"],
  props: {
    type: {
      type: String,
      default: "toolbar",
    },
  },

  components: {
    IonIcon,
    IonButton,
  },

  data() {
    return {
      cloudDownload,
    };
  },

  methods: {},
};
</script>

<style scoped lang="scss">
ion-button {
  --background: linear-gradient(
    116.63deg,
    #fff 8.97%,
    #ccc 92.93%
  ) !important;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 6px !important;
  // --background: #fff !important;
  --color: #{$primary-color} !important;
  --border-radius: 10px !important;
}
</style>