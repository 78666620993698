<template>
  <div id="no-result">
    <div class="image">
      <slot name="image"></slot>
    </div>

    <h4>{{ title }}</h4>

    <p v-html="description"></p>

    <div class="line" :style="'width: ' + lineWidth" v-if="actionText"></div>

    <ion-button
      v-if="actionText"
      class=""
      fill="solid"
      expand="block"
      color="primary"
      @click="clickHandler()"
      size="small"
    >
      <span>{{ actionText }}</span><ion-icon slot="end" :icon="arrowForward"></ion-icon>
    </ion-button>

    <ion-button
      v-if="actionText2"
      class=""
      fill="solid"
      expand="block"
      color="secondary"
      @click="clickHandler2()"
    >
      <span>{{ actionText2 }}</span>
    </ion-button>

    <ion-button
      v-if="actionText3"
      class=""
      fill="solid"
      expand="block"
      color="medium"
      @click="clickHandler3()"
    >
      <span>{{ actionText3 }}</span>
    </ion-button>
  </div>
</template>

<script>
import { IonButton, IonIcon } from "@ionic/vue";
import { arrowForward } from "ionicons/icons";

export default {
  props: {
    title: {
      required: true,
    },
    description: {
      required: true,
    },
    actionText: {
      required: true,
    },
    clickHandler: {
      required: true,
    }, 
    actionText2: {
      required: true,
    },
    clickHandler2: {
      required: true,
    },
    actionText3: {
      required: true,
    },
    clickHandler3: {
      required: true,
    },
    lineWidth: {
      default: "33%",
    },
  },

  data() {
    return {
      arrowForward,
    }
  },
  components: {
    IonButton,
    IonIcon
  },
};
</script>

<style scoped lang="scss">
#no-result {
  width: 100%;
  height: 100%;
  // background: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0px;

  .image {
    width: 40%;
  }

  p {
    position: relative;
    // margin-bottom: 56px;
    margin-top: 10px;
    color: #888;
    text-align: center;
    padding: 0 20px;
    font-size: calc(14px + $extra-font-size);

    // &:after {
    //   content: "";
    //   width: 100%;
    //   height: 1px;
    //   background: #dedede;
    //   position: absolute;
    //   bottom: -29px;
    //   left: 0;
    // }
  }

  h4 {
    font-weight: 800;
    color: #000;
  }

  .line {
    height: 1px;
    margin: 10px auto 10px;
    background: #dedede;
  }

  ion-button {
    margin-top: 10px;
  }
}
</style>