<template>
  <div id="navbar">
    <div class="navbuttons">
      <!-- <ion-button
        router-link="/home"
        :class="{ active: currentRoute.path == '/home'}"
      >
        <ion-icon :icon="homeSharp"></ion-icon>
      </ion-button> -->

      <ion-tab-button
        mode="ios"
        @click.prevent="goTo('/home')"
        :class="buttonClass('/home', false)"
      >
        <ion-icon
          :icon="currentRoute.path == '/home' ? homeSharp : homeOutline"
        ></ion-icon>
        <ion-label>{{ lang.menu_bar.home }}</ion-label>
      </ion-tab-button>

      <ion-tab-button
        mode="ios"
        @click="goTo('/spots')"
        :class="buttonClass('/spots', newSpot)"
      >
        <ion-icon
          :icon="currentRoute.path == '/spots' ? pulseSharp : pulseOutline"
        ></ion-icon>
        <ion-label>{{ lang.spots ?? "Spots" }}</ion-label>
      </ion-tab-button>

      <ion-tab-button
        mode="ios"
        @click="goTo('/futures')"
        :class="buttonClass('/futures', newFuture)"
      >
        <ion-icon
          :icon="
            currentRoute.path == '/futures' ? receiptSharp : receiptOutline
          "
        ></ion-icon>
        <ion-label class="translate">{{
          lang.menu_bar?.futures ?? "Futures"
        }}</ion-label>
      </ion-tab-button>

      <ion-tab-button
        mode="ios"
        @click="goTo('/news')"
        :class="buttonClass('/news', newsUpdate)"
      >
        <ion-icon
          :icon="currentRoute.path == '/news' ? newspaper : newspaperOutline"
        ></ion-icon>
        <ion-label>{{ lang.menu_bar.news }}</ion-label>
      </ion-tab-button>

      <ion-tab-button
        mode="ios"
        @click="goTo('/notifications')"
        :class="buttonClass('/notifications', newNotification)"
      >
        <ion-icon
          :icon="
            currentRoute.path == '/notifications'
              ? notifications
              : notificationsOutline
          "
        ></ion-icon>
        <ion-label>{{ lang.menu_bar.notifications }}</ion-label>
      </ion-tab-button>
    </div>
  </div>
</template>

<script>
import {
  // IonButton,
  IonIcon,
  IonLabel,
  //   IonBadge,
  IonTabButton,
} from "@ionic/vue";

import {
  homeSharp,
  pulseSharp,
  newspaperOutline,
  newspaper,
  notificationsOutline,
  notifications,
  homeOutline,
  pulseOutline,
  receiptSharp,
  receiptOutline,
} from "ionicons/icons";
import { useRouter } from "vue-router";

export default {
  props: ["currentRoute"],
  inject: ["store"],
  components: {
    // IonButton,
    IonIcon,
    IonLabel,
    // IonBadge,
    IonTabButton,
  },
  data() {
    return {
      homeOutline,
      homeSharp,
      pulseOutline,
      pulseSharp,
      newspaperOutline,
      notificationsOutline,
      newspaper,
      notifications,
      receiptSharp,
      receiptOutline,
      newSpot: false,
      newFuture: false,
      signalUpdate: false,
      newsUpdate: false,
      newNotification: false,
    };
  },

  methods: {
    goTo(route) {
      this.$router.push(route);
    },

    buttonClass(route, notify) {
      let btnClass =
        "md tab-has-label tab-has-icon tab-layout-icon-top ion-activatable ion-selectable ion-focusable hydrated";
      if (this.currentRoute.path == route) {
        btnClass += " active";
      }

      if (notify) {
        btnClass += " notify";
      }

      return btnClass;
    },
  },

  computed: {
    compActionTrigger() {
      return this.store.state.compActionTrigger;
    },
  },

  watch: {
    compActionTrigger() {
      let action = this.store.state.compAction;

      switch (action.type) {
        case "new_signal_outside_spots": //There's a spots update outside spots component
          this.newSpot = true;
          break;
        case "new_signal_outside_futures": //There's a futures update outside Futures component
          this.newFuture = true;
          break;
        case "news_update_news":
        case "news_update_outside_news":
          this.newsUpdate = true;
          break;
        case "new_notification_notifications":
        case "new_notification_outside_notifications":
          this.newNotification = true;
          break;
        case "new_spot_seen":
          this.newSpot = false;
          break;
        case "new_future_seen":
          this.newFuture = false;
          break;
        case "news_seen":
          this.newsUpdate = false;
          break;
        case "notification_seen":
          this.newNotification = false;
          break;
      }
    },
  },

  mounted() {
    let self = this;

    // Handle emitter events
    self.emitter.on("reflect_notifications", (data) => {
      // console.log("Menu bar recieved emitted event from store");
      if (data.spots) {
        this.newSpot = true;
      }

      if (data.futures) {
        this.newFuture = true;
      }

      if (data.notifications) {
        // console.log("turning on new sell signal");
        this.newNotification = true;
      }

      if (data.news) {
        // console.log("turning on news");
        this.newsUpdate = true;
      }
    });

    self.emitter.on("new_notification_outside_notifications", () => {
      this.newNotification = true;
    });
  },

  setup() {
    const router = useRouter();
    return { router };
  },
};
</script>

<style scoped lang="scss">
#navbar {
  // position: fixed;
  width: 100%;
  border-top: 1px solid #ccc;
  padding: 5px 0;

  .navbuttons {
    background: #fff;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;

    ion-tab-button {
      // --background: #ffffff !important;
      // --box-shadow: none;
      --color: #555;
      // // --color: #{lighten($primary-color, 10%)};;
      // font-size: calc(20px + $extra-font-size);
      // --padding-bottom: 0;
      // --padding-end: 0;
      // --padding-top: 0;
      // --padding-start: 0;
      position: relative;

      &.active {
        --color: var(--ion-color-primary);
      }

      ion-icon {
        font-size: calc(25px + $extra-font-size);
      }

      ion-label {
        font-size: calc(11px + $extra-font-size);
      }

      &.notify {
        ion-icon {
          position: relative;
          overflow: unset;
          contain: unset;

          &:before {
            content: "";
            width: 7px;
            height: 7px;
            background: red;
            position: absolute;
            top: 0;
            right: -10px;
            z-index: 1;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>