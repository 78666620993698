<template>
  <!-- <base-layout :noPageHeader="noPageHeader" :toolbarClass="toolbarClass" :headerClass="headerClass" :pageDefaultBackLink="pageDefaultBackLink"> -->
  <base-layout-2
    :noPageHeader="false"
    :toolbarClass="'home'"
    :toolbarColor="'primary'"
    :headerClass="'fixed-home'"
    :pageTitle="lang.reviews_modal.title"
    :contentColor="'primary'"
  >
    <template v-slot:actions-start>
      <ion-button @click="dismiss()">
        <ion-icon slot="icon-only" :icon="arrowBack"></ion-icon>
      </ion-button>
    </template>

    <template v-slot:ion-content>
      <div class="page-container ion-padding">
        <no-result
          :title="lang.reviews_modal.body.title"
          :description="lang.reviews_modal.body.description"
          :actionText="lang.reviews_modal.body.action_text_1"
          :clickHandler="rateUs"
          :actionText2="lang.reviews_modal.body.action_text_2"
          :clickHandler2="later"
          :actionText3="lang.reviews_modal.body.action_text_3"
          :clickHandler3="never"
        >
          <template v-slot:image>
            <img :src="star" alt="" srcset="" />
          </template>
        </no-result>
      </div>
    </template>
  </base-layout-2>
</template>


<script>
// import {
//   IonButton,
//   IonIcon,
// } from "@ionic/vue";
import { arrowBack, checkmark } from "ionicons/icons";
import BaseLayout2 from "../layouts/Base2";
import { IonButton, IonIcon } from "@ionic/vue";
import star from "../../../src/assets/img/shooting-star.png";
import NoResult from "../components/cat-3/NoResultMultiple.vue";

export default {
  inject: ["store"],
  props: ["dismiss"],
  data() {
    return {
      arrowBack,
      checkmark,
      star,
      filter: null,
    };
  },
  components: {
    IonButton,
    IonIcon,
    NoResult,
    "base-layout-2": BaseLayout2,
  },

  methods: {
    async rateUs() {
      await this.store.setters.reviews({
        reviewed: true
      });
      this.dismiss();
      window.location.href = this.appStoreUrl;
    },

    later() {
      this.dismiss();
    },

    async never() {
      await this.store.setters.reviews({
        deniedReview: true
      });
      this.dismiss();
    }
  },

  created() {
    // console.log({
    //   modal: this
    // });
  },
};
</script>


<style scoped lang="scss">
.page-container {
  padding-top: 20px;
  border-top-left-radius: 40px;
  background: #fafafc;
  overflow: auto;
  @include toolbar-content-size-1;
}
</style>