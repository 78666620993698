<template>
  <banner :actions="cta_banner_actions"></banner>
</template>

<script>
import Banner from "./Banner.vue";
import { cloudDownload } from "ionicons/icons";

export default {
  inject: ["store"],

  computed: {
    cta_banner_actions() {
      let self = this;
      return [
        {
          text: "Download The App",
          icon: cloudDownload,
          handler() {
            self.downloadTheApp();
          },
        },
      ];
    },
  },

  components: {
    Banner,
  },

  created() {},
};
</script>