import {
    toastController
} from "@ionic/vue";

export default {
    data: function () {
        return {
            toast: null,
        }
    },

    methods: {
        //Dwonload app in browser mode
        downloadTheApp(text=null) {
            this.store.actions.registerWebEvent("Clicked on download")
            this.emitter.emit("openAppStoreView", text);
        },

        //Toggle guide mode
        guideMode(bool, self) {
            let app = document.getElementById("app");

            if (bool) {
                //Make rest of app unclickable until tour guide is off
                app.classList.add("tour-guide-mode");

                //Scroll content to top to avoid glitches from tourjs
                self.content.$el.scrollToTop();
            } else {
                //Leave tour-guide-mode class from div#app
                app.classList.remove("tour-guide-mode");
            }

            //Change guideMode in state for components like App.vue
            self.store.setters.guideMode(bool);
        },

        async showToast(options) {
            this.toast = await toastController.create(options);
            this.toast.present();
        },

        networkError(msg = null, buttons = null) {
            /* 
                msg: String (mesage to send to display if provided) optional
                callback: Object ({
                    text: ...,
                    handler: ...,
                }) (Action text, and action callback)
            */

            let self = this;
            let options = {
                color: "danger",
                message: msg ? msg : self.lang.connection_error0.default_msg,
            };

            if (buttons) {
                options.buttons = buttons;
            } else {
                options.buttons = [{
                    text: "Dismiss",
                    role: "cancel",
                },],
                    options.duration = 5000
            }

            self.showToast(options);
        },

        successMessage(msg = null, buttons = null) {

            let self = this;
            let options = {
                color: "success",
                message: msg ? msg : "Operation successul!",
            };

            if (buttons) {
                options.buttons = buttons;
            } else {
                options.buttons = [{
                    text: "Dismiss",
                    role: "cancel",
                },],
                    options.duration = 5000
            }

            self.showToast(options);
        },

        ionViewWillLeave() {
            if (this.toast) {
                this.toast.dismiss();
            }
        },
    },

    computed: {
        platform() {
            return this.store.state.deviceData.platform
        },

        browser() {
            // return false;
            return true;
            // return this.store?.state?.deviceData?.platform == "web";
        },

        lang() {
            if (this.store?.state?.settings) {
                return window.lang[this.store.state.settings.lang];
            } else {
                return window.lang.en
            }

        },

        appStoreUrl() {
            let platform = "direct_apk";

            switch (platform) {
                case "legacy":
                    return null;


                case "direct_apk":
                    return "https://com-pro-signals.en.aptoide.com/app?store_name=catappult&app_id=67539542";


                case "galaxy_store":
                    return null;


                case "huawei_app_gallery":
                    return "https://appgallery.huawei.com/app/C110594991";


                case "xiaomi_app_store":
                    return null;


                case "palmstore":
                    return null;


                case "oppo_store":
                    return null;


                case "aptoide":
                    return "https://com-pro-signals.en.aptoide.com/app?store_name=catappult&app_id=67539542";


                case "amazon_app_store":
                    return "https://www.amazon.com/dp/B0D2YKC35K/ref=apps_sf_sta";


                case "apkpure":
                    return null;


                case "playstore":
                    return null;


            }
        },

        tradingViewLocale() {

            let locale = this.store?.state?.settings?.lang;

            if (locale) {
                switch (locale) {
                    case "hi":
                        return "in";

                    case "de":
                        return "de_DE";

                    case "pt":
                        return "br";

                    case "vi":
                        return "vi_VN";

                    case "zh":
                        return "zh_CN";

                    case "fa":
                        return "fa_IR";

                    default:
                        return locale;
                }
            } else {
                return "en";
            }
        },

        momentJsLocale() {
            let locale = this.store?.state?.settings?.lang;

            if (locale) {
                switch (locale) {
                    case "pa":
                        return "pa-in";

                    case "zh":
                        return "zh_cn";

                    default:
                        return locale;
                }
            } else {
                return "en";
            }
        },

        googleTranslateLocale() {
            let locale = this.store?.state?.settings?.lang;

            if (locale) {
                switch (locale) {
                    case "zh":
                        return "zh-CN";

                    default:
                        return locale;
                }
            } else {
                return "en";
            }
        }
    }
}