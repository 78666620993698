<template>
  <div id="loader-wrap">
    <div id="loader">
        <img src="../../../assets/img/logo-black.png" width="100" />
        <ion-spinner name="dots" v-show="loading"></ion-spinner>
    </div>
  </div>
</template>

<script>
// import { IonImg } from '@ionic/vue';
export default {
  props: ["loading"],
  components: {
    //   IonImg,
  },
  methods: {
    // navigateTo(route) {
    // },
  },
};
</script>

<style scoped lang="scss">
#loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;

    img {
        opacity: .7;
        width: 70px;
    }

    ion-spinner {
      margin-top: 20px;
    }
}
</style>