<template>
  <div id="select-app-store" class="slide-fade-up-in">
    <div class="close-select" @click="closeView">
      <ion-icon :icon="close"></ion-icon>
    </div>
    <p v-if="text && !downloading" class="prompt">{{ text }}</p>
    <div class="badges" v-show="badges">
      <div
        v-for="store in storesToDisplay"
        :key="store"
        :class="{
          'badge-wrap': true,
          'pop-in': true,
          recommended: store.recommended,
          fast: store.fast,
        }"
        @click="openStore(store)"
      >
        <div v-if="store.recommended" class="recommended">RECOMMENDED</div>
        <div v-if="store.fast" class="fast">FASTEST</div>
        <img class="badge" :src="store.image" alt="" />
      </div>
      <!-- <div class="badge-wrap pop-in">
        <img
          class="badge"
          src="@/assets/img/app_store_badges/Palmstore_badge.png"
          alt=""
        />
      </div> -->
    </div>

    <div id="thank-you-for-download" v-show="downloading">
      <div id="download-icon">
        <ion-icon :icon="cloudDownload"></ion-icon>
      </div>

      <p id="downloading" class="slide-fade-up-in">
        <span>Uptrade is downloading...</span>
        <a href="https://webapp.upchainsignals.com/Uptrade.apk"
          >Re-download <ion-icon :icon="refresh"></ion-icon
        ></a>
      </p>

      <div
        :class="'card slide-fade-up-in ' + ('card' + key)"
        v-for="(item, key) in steps"
        :key="key"
      >
        <div class="inner">
          <p class="title">How to install</p>

          <div id="steps">
            <div class="progressbar">
              <div class="bar"></div>

              <span
                :class="{
                  step: true,
                  active: item.step == 1,
                }"
                >1</span
              >

              <span
                :class="{
                  step: true,
                  active: item.step == 2,
                }"
                >2</span
              >

              <span
                :class="{
                  step: true,
                  active: item.step == 3,
                }"
                >3</span
              >
            </div>
          </div>

          <div class="description">
            <p>{{ item.description }}</p>
          </div>

          <component :is="components[item.footer]"></component>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { IonIcon } from "@ionic/vue";
import { close, cloudDownload, refresh } from "ionicons/icons";
import amazonImage from "@/assets/img/app_store_badges/Amazon.png";
import apkpureImage from "@/assets/img/app_store_badges/APKPure.png";
import aptoideImage from "@/assets/img/app_store_badges/Aptoide_badge_black.png";
import directAPKImage from "@/assets/img/app_store_badges/Direct.png";
import galaxyImage from "@/assets/img/app_store_badges/GalaxyStore_English.png";
import huaweiImage from "@/assets/img/app_store_badges/Huawei.png";
import xiaomiImage from "@/assets/img/app_store_badges/Mi_App_Mall_Badge.png";
import oppoImage from "@/assets/img/app_store_badges/Oppo.png";
import palmstoreImage from "@/assets/img/app_store_badges/Palmstore_badge.png";
import FileDownloadedVue from "../cat-3/download-steps/FileDownloaded.vue";
import unknownAppsVue from "../cat-3/download-steps/unknownApps.vue";
import completeVue from "../cat-3/download-steps/Complete.vue";
import { markRaw } from "vue";

const components = {
  fileDownload: markRaw(FileDownloadedVue),
  unknownApps: markRaw(unknownAppsVue),
  complete: markRaw(completeVue),
};

export default {
  emits: ["close"],
  inject: ["store"],
  props: {
    deviceStore: {
      type: String,
      default: null,
    },
    text: {
      default: null,
    },
  },
  data() {
    return {
      close,
      cloudDownload,
      refresh,
      components,
      allStores: {
        galaxy: {
          image: galaxyImage,
          url: "",
          recommended: false,
          name: "Galaxy store",
          regex: /galaxy|samsung/i,
        },
        huawei: {
          image: huaweiImage,
          url: "https://appgallery.huawei.com/app/C110594991",
          recommended: false,
          name: "Huawei App Gallery",
          regex: /huawei/i,
        },
        xiaomi: {
          image: xiaomiImage,
          url: "",
          recommended: false,
          name: "Xiaomi App store",
          regex: /xiaomi|redmi/i,
        },
        palmstore: {
          image: palmstoreImage,
          url: "",
          recommended: false,
          name: "Palmstore",
          regex: /tecno|infinix|itel/i,
        },
        oppo: {
          image: oppoImage,
          url: "",
          recommended: false,
          name: "Oppo store",
          regex: /oppo/i,
        },
        aptoide: {
          image: aptoideImage,
          url: "https://com-pro-signals.en.aptoide.com/app?store_name=catappult&app_id=67539542",
          recommended: false,
          name: "Aptoide",
        },
        amazon: {
          image: amazonImage,
          url: "https://www.amazon.com/dp/B0D2YKC35K/ref=apps_sf_sta",
          recommended: false,
          name: "Amazon app store",
        },
        direct: {
          image: directAPKImage,
          url: "https://webapp.upchainsignals.com/Uptrade.apk",
          recommended: false,
          name: "Direct apk install",
        },
        apkpure: {
          image: apkpureImage,
          url: "",
          recommended: false,
          name: "Apkpure",
        },
      },
      downloading: false,
      badges: true,
      storesToDisplay: [],
      steps: [
        {
          step: 1,
          description: "Download and open the installation file",
          footer: "fileDownload",
        },

        {
          step: 2,
          description: "Grant permission to install apps",
          footer: "unknownApps",
        },

        {
          step: 3,
          description: "Complete the installation and open Uptrade",
          footer: "complete",
        },
      ],
    };
  },
  components: {
    IonIcon,
  },

  methods: {
    closeView() {
      this.$emit("close");

      this.store.actions.registerWebEvent("Closed appstores modal");
    },

    setStoresToDisplay() {
      // this.allStores.aptoide.recommended = true;
      // this.allStores.direct.fast = true;

      this.storesToDisplay.push(this.allStores.aptoide);
      this.storesToDisplay.push(this.allStores.amazon);
      // this.storesToDisplay.push(this.allStores.huawei);
      // this.storesToDisplay.push(this.allStores.direct);
    },

    async getDeviceInfo() {},

    openStore(store) {
      this.store.actions.registerWebEvent("Selected " + store.name + "'s url");

      if (store.name == "Direct apk install") {
        this.badges = false;
        this.downloading = true;
        window.location.href = store.url;
        return;
      }

      window.open(store.url, "_blank");
    },
  },

  computed: {},

  mounted() {},

  created() {
    this.setStoresToDisplay();
  },
};
</script>
  
<style scoped lang="scss">
#select-app-store {
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.95);
  animation-duration: 0.3s;
  flex-direction: column;


  .prompt {
    color: #aaa;
    font-weight: bold;
    font-size: 12px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }

  .badges {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 20px;

    .badge-wrap {
      width: calc(50% - 5px);
      position: relative;
      opacity: 0;

      &:nth-child(1) {
        animation-delay: 0.3s;
      }

      &:nth-child(2) {
        animation-delay: 0.5s;
      }

      &:nth-child(3) {
        animation-delay: 0.7s;
      }

      &:nth-child(4) {
        animation-delay: 0.9s;
      }

      &:active {
        transform: scale(1.03) !important;
      }

      .recommended,
      .fast {
        color: #000;
        background: #{darken($green, 0%)};
        padding: 3px;
        position: absolute;
        top: -19px;
        left: 0px;
        font-size: 8px;
        // font-weight: bold;
        border-radius: 5px;
      }

      .badge {
        border-radius: 12px;
        border: 3px solid #555;
      }

      &.recommended {
        .badge {
          border-color: #{darken($green, 0%)};
        }
      }

      &.fast {
        .badge {
          border-color: #{darken($green, 0%)};
        }
      }
    }
  }
}

.close-select {
  position: absolute;
  top: 20px;
  right: 20px;

  ion-icon {
    color: #fff;
    font-size: 30px;
  }
}

#thank-you-for-download {
  color: #fff;
  text-align: center;
  height: 100%;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 20px;
}

#download-icon {
  ion-icon {
    font-size: 30px;
  }
}

#downloading {
  margin-bottom: 20px;
  font-weight: bold;

  a {
    color: $green;
    display: block;

    &:active {
      color: #{lighten($green, 30%)};
    }

    ion-icon {
      position: relative;
      top: 2px;
    }
  }
}

.card {
  background: #fff;
  border-radius: 20px;
  color: #000;
  padding: 20px;
  margin-bottom: 30px;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 1s;

  .inner {
    .title {
      font-size: 13px;
      margin-top: 0;
    }

    .description {
      p {
        font-size: 13px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

.card0 {
  animation-delay: 0.2s;
}

.card1 {
  animation-delay: 0.4s;
}

.card2 {
  animation-delay: 0.6s;
}

#steps {
  width: 100%;
}

.progressbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  padding: 0 50px;
  z-index: 1;

  .bar {
    content: "";
    width: calc(100% - 100px);
    height: 1px;
    background: #eee;
    position: absolute;
    top: 15px;
    z-index: -1;
  }

  .step {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    background: #fff;
    font-weight: bold;

    &.active {
      background: linear-gradient(
        116.63deg,
        $green 8.97%,
        #{darken($green, 20%)} 92.93%
      );
      border: unset;
      color: #fff;
    }
  }
}
</style>