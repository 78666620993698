<template>
  <div class="demonstration">
    <div class="file-downloaded">
      <div class="check">
        <img :src="downloadCheck" alt="" />
      </div>

      <div class="middle">
        <span>File downloaded</span>
      </div>

      <div class="open">
        <span>Open</span>
      </div>
    </div>
  </div>
</template>

<script>
import downloadCheck from "@/assets/img/download-check.svg";

export default {
    data() {
        return {
            downloadCheck
        }
    }
};
</script>

<style scoped lang="scss">
.demonstration {
  width: 100%;

  .file-downloaded {
    // margin: 0 auto;
    // width: 210px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    border-radius: 10px;
    // border: 1px solid #000;
    font-size: 11px;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 6px;

    .check {
      img {
        // width: 18px;
      }
    }

    .middle {
      border-bottom: 3px solid #ccc;
      padding-bottom: 2px;
      padding-right: 10px;
    }

    .open {
      color: #{darken($green, 10%)};
    }
  }
}
</style>