import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';
import moment from 'moment';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/app.scss';

import BaseLayout from "./views/layouts/Base.vue";
import { IonSpinner } from "@ionic/vue";
import globalVars from "./mixins/gobal-variables";
import mitt from 'mitt';
// import lang from "./assets/lang/lang";
// import "./Trading-view";

window.axios = require('axios');
window.moment = moment;

//Set up language files
import ar from "./assets/lang/arabic.json";
import bn from "./assets/lang/bengali.json";
import zh from "./assets/lang/chinese.json";
import en from "./assets/lang/english.json";
import fr from "./assets/lang/french.json";
import de from "./assets/lang/german.json";
import hi from "./assets/lang/hindi.json";
import id from "./assets/lang/indonesian.json";
import it from "./assets/lang/italian.json";
import ja from "./assets/lang/japanese.json";
import ko from "./assets/lang/korean.json";
import mr from "./assets/lang/marathi.json";
import pt from "./assets/lang/portuguese.json";
import ro from "./assets/lang/romanian.json";
import ru from "./assets/lang/russian.json";
import es from "./assets/lang/spanish.json";
import tr from "./assets/lang/turkish.json";
import vi from "./assets/lang/vietnamese.json";
import './registerServiceWorker'

window.lang = {
  ar,
  bn,
  zh,
  en,
  fr,
  de,
  hi,
  id,
  it,
  ja,
  ko,
  mr,
  pt,
  ro,
  ru,
  es,
  tr,
  vi,
};

const app = createApp(App)
  .use(IonicVue)
  .use(router);


const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.config.globalProperties.$filters = {
  sentenceCase(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  },

  signalTimeLT(value, locale) {
    let signalTime = window.moment(value).local();
    let currentTime = window.moment().local();
    window.moment.locale(locale);
    if (signalTime.isSame(currentTime, "day")) {
      return signalTime.format("LT");
    } else {
      return signalTime.format("ll");
    }
  },

  time_lll(value) {
    let theTime = window.moment(value).local();
    return theTime.format("lll");
  },

  sign(value) {
    if (value === null) {
      return "";
    } else {
      return Number(value) > 0 ? "+" : "";
    }
  },

  mood(value) {
    if (value === null) {
      return "dark";
    } else {
      value = Number(value);

      if (value > 0) {
        return "success";
      } else if (value == 0) {
        return "warning";
      } else {
        return "danger";
      }
    }
  },

  morphReplaceText(text, replacements) {
    if (typeof (text) != "string") {
      return null;
    }

    let result = text;

    replacements.forEach((item) => {
      let char = item[0];
      let replacment = item[1];
      result = result.replace(char, replacment)
    })

    return result;
  }
}

app.component("base-layout", BaseLayout);
app.component("ion-spinner", IonSpinner);
app.mixin(globalVars);

router.isReady().then(() => {
  app.mount('#app');
});