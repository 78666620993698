<template>
  <ion-app id="app">
    <ion-router-outlet v-if="routerOn" :animated="pageAnimation" />
    <menu-bar
      :currentRoute="currentRoute"
      v-if="currentRoute.meta.navBar"
    ></menu-bar>
    <loader v-show="loader.show" :loading="loader.status"></loader>
    <select-app-store
    @close="appStoreView = false"
    v-if="appStoreView"
    :text="appStoreViewText"
    ></select-app-store>
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet,
  useBackButton,
  modalController,
} from "@ionic/vue";
import MenuBar from "./views/components/MenuBar";
import Loader from "./views/components/cat-3/Loader";
import ReviewModal from "./views/pages/ReviewModal.vue";
import { defineComponent } from "vue";

// import noMenuRoutes from "./no-menu-routes";
import { store } from "./store";
import { Plugins } from "@capacitor/core";
import SelectAppStore from "./views/components/UI components/SelectAppStore.vue";
const { StatusBar, Toast, NativeHelper, Keyboard } = Plugins;

export default defineComponent({
  name: "App",
  provide: { store },
  data() {
    return {
      loaderSpinning: true,
      routerOn: true,
      exit: "warn",
      reviewModal: null,
      appStoreView: false,
      appStoreViewText: false
    };
  },
  components: {
    IonApp,
    MenuBar,
    Loader,
    IonRouterOutlet,
    SelectAppStore,
  },

  watch: {
    $route(to, from) {
      from;
      store.setters.currentRoute(to);

      if (to.meta.pageAnimation) {
        store.setters.pageAnimation(true);
      } else {
        store.setters.pageAnimation(false);
      }
    },

    compActionTrigger() {
      let action = store.state.compAction;
      let self = this;

      switch (action.type) {
        case "navigate":
          self.$router.push(action.data.route);
          if (action.data.callback) {
            action.data.callback();
          }
          break;
        case "rerender-router":
          self.routerOn = false;
          self.$nextTick(() => {
            self.routerOn = true;
            store.state.compAction.data.callback();
          });
          break;
      }
    },
  },

  methods: {
    //Attempt to exit (want first time, exit second time)
    attemptExit() {
      let self = this;
      if (self.exit == "warn") {
        self.exit = "exit";
        self.warnExit();
        setTimeout(() => {
          self.exit = "warn";
        }, 3500);
      } else {
        // App.exitApp();
        if (self.currentRoute.name == "AllowDraw") {
          store.actions.backExitedFromAllowDraw();
        }
        NativeHelper.moveToBackground();
      }
    },

    async warnExit() {
      await Toast.show({
        text: "Click exit again",
        duration: "long",
      });
    },

    initialize() {
      store.loader(true);
      store.loading(true);

      let self = this;
      store
        .initApp()
        .then(async () => {
          //If we're visiting the webapp, register as new visitor or reload
          if (self.browser) {
            store.actions.newVisitor();
          }

          //Initialize TourJs. A library for guiding the user through app features
          window.tourClient = new window.tourguide.TourGuideClient({
            exitOnClickOutside: false,
            targetPadding: 5,
            backdropColor: "rgba(20,20,21,0.54)",
            showStepProgress: false,
            showStepDots: false,
          });

          try {
            NativeHelper.startClipService();
          } catch (e) {
            console.log(e);
          }

          //ALTERED_FOR_TEST
          var firstRoute = await store.getters.firstRoute();
          // var firstRoute = "/general-notification";
          // store.loader(false);

          let onEnterWelcomePage = () => {
            store.actions.setStatusBarStyleDark();
            store.loader(false);
            this.emitter.emit("welcomeIsReady");
          };

          if (firstRoute == "/welcome") {
            if (this.currentRoute.name != "Welcome") {
              self.$router
                .push({
                  path: "/welcome",
                  replace: true,
                })
                .then(() => {
                  setTimeout(() => {
                    onEnterWelcomePage();
                  }, 200);
                });
            } else {
              onEnterWelcomePage();
            }
          } else {
            self.$router.push({
              path: firstRoute,
              replace: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          store.loading(false);

          let lang = window.lang[store.state.settings.lang];

          // if (error.type == "network-error") {
          self.networkError(lang.connection_error0.msg, [
            {
              side: "end",
              icon: "refresh",
              text: lang.connection_error0.action1,
              handler: () => {
                self.initialize();
              },
            },
          ]);
          // }
        });
    },

    dismissReviewModal() {
      modalController.dismiss();
    },

    async getReview() {
      let self = this;
      self.reviewModal = await modalController.create({
        component: ReviewModal,
        cssClass: "review-modal",
        componentProps: {
          dismiss: self.dismissReviewModal,
        },
        animated: false,
      });

      return self.reviewModal.present();
    },
  },

  computed: {
    guideMode() {
      return store.state.guideMode;
    },

    loader() {
      return store.getLoader();
    },

    pageAnimation() {
      return store.getters.pageAnimation();
    },

    currentRoute() {
      return store.state.currentRoute;
    },

    compActionTrigger() {
      return store.state.compActionTrigger;
    },
  },

  mounted() {
    let self = this;

    //Set up back button
    useBackButton(10, (processNextHandler) => {
      if (self.guideMode) {
        self.attemptExit();
        return;
      }

      switch (self.currentRoute.name) {
        case "Home":
        case "AllowDraw":
        case "UpdateApp":
          self.attemptExit();
          break;

        case "Signal":
          if (
            store.state.signalInView &&
            store.state.signalInView.data.openedOnLaunch
          ) {
            self.$router.push({
              path: "/spots",
              replace: true,
            });
          } else {
            processNextHandler();
          }
          break;
        case "NewsUpdate":
          if (store.state.newsInView && store.state.newsInView.openedOnLaunch) {
            self.$router.push({
              path: "/news",
              replace: true,
            });
          } else {
            processNextHandler();
          }
          break;

        case "Welcome":
          store.actions.triggerCompAction("welcome-back", null);
          break;

        case "Spots":
        case "Notifications":
        case "News":
          if (self.$router.options.history.state.back) {
            processNextHandler();
          } else {
            self.$router.push("/home");
          }
          break;

        case "GeneralNotification":
          if (
            store.state.generalNotificationInView &&
            store.state.generalNotificationInView.openedOnLaunch
          ) {
            self.$router.push({
              path: "/notifications",
              replace: true,
            });
          } else {
            processNextHandler();
          }
          break;

        default:
          processNextHandler();
          break;
      }
    });

    // Set up keyboard
    //Bind keyboard
    Keyboard.addListener("keyboardDidShow", () => {
      store.setters.keyboard(true);
    });

    Keyboard.addListener("keyboardDidHide", () => {
      store.setters.keyboard(false);
    });
  },

  created() {
    let self = this;

    //Set status bar
    StatusBar.setOverlaysWebView({
      overlay: true,
    });

    store.actions.setStatusBarStyleLight();

    store.setters.currentRoute(self.$router.currentRoute);

    //expose emitter and app instance to store.js
    store.setters.emitter(self.emitter);
    store.setters.appInstance(self);

    self.emitter.on("appSignedIn", async () => {
      store.actions.setStatusBarStyleDark();
      store.loader(false);
      store.setters.appReady(true);
      store.actions.handleBackgroundDeliveredNotifs();

      //Sync Notification Permission Status
      store.actions.syncNotificationPermissionStatus(true);

      //Subscribe to default topics if not already done
      let notifsActivated = await store.plugins.Storage.get({
        key: "notifsActivated",
      });

      if (!notifsActivated.value) {
        let activateNotifs = await store.actions.subscribeToDefaultTopics();
        if (activateNotifs) {
          store.plugins.Storage.set({
            key: "notifsActivated",
            value: "true",
          });
        }
      } else {
        // console.log("Notifications already activated...");
      }

      if (!self.browser) {
        //Initialize reviews
        (async () => {
          let result = await store.plugins.Storage.get({
            key: "reviews",
          });

          let reviews, appResumeCount;

          if (result.value) {
            reviews = JSON.parse(result.value);
            if (reviews.deniedReview || reviews.reviewed) {
              store.setters.reviews({
                deniedReview: reviews.deniedReview,
                reviewed: reviews.reviewed,
              });
              return;
            }
            appResumeCount = Number(reviews.appResumeCount) + 1;
          } else {
            appResumeCount = 1;
          }

          await store.actions.respondToAppResumeCount(appResumeCount);
        })();
      }

      //Ensure that app is up to date if this is first launch.
      if (!store.state.checkedAppVersion) {
        let upToDate = await store.getters.upToDate();

        if (!upToDate) {
          self.$router.push({
            path: "/update-app",
            replace: true,
          });
        }
      }
    });

    //This event will be trigger by the downloadApp function in gobal-variables.js
    //To open the app store view
    self.emitter.on("openAppStoreView", function(text) {
      self.appStoreView = true;
      console.log({
        text
      })
      self.appStoreViewText = text
    })
    
    self.initialize();

    // console.log({
    //   store,
    // });
  },
});
</script>

<style lang="scss">
ion-app#app {
  display: flex;

  ion-router-outlet {
    flex-grow: 1;
    position: unset;
  }
}

.slide-up-enter-active {
  transition: all 0.3s ease !important;
}
.slide-up-leave-active {
  transition: all 0.3s ease !important;
}
.slide-up-enter
/* .slide-up-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(100px);
}

.slide-up-leave-to
/* .zoom-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#app-body {
  min-height: unset !important;
  top: unset !important;
}

.skiptranslate {
  display: none !important;
}

.goog-te-spinner-pos {
  display: none !important;
}
</style>